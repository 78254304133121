import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'KSR Automation: The ultimate tool for resellers.',
  lang: 'en',
  description: 'The highest performing e-commerce automation',
};

// NAV DATA
export const navData = {
  links: [
    {
      id: nanoid(),
      name: 'Dashboard Access',
      url: 'https://dash.ksrautomation.co',
      external: true,
    },
    {
      id: nanoid(),
      name: 'Features',
      url: 'projects',
    },
    {
      id: nanoid(),
      name: 'FAQ',
      url: 'contact',
    },
  ],
};

// HERO DATA
export const heroData = {
  img: 'header-strip.jpg',
  title: 'KSR Automation:',
  subtitle: ' The ultimate tool for resellers.',
  cta: 'Learn More',
  waitlist: 'Join Our Waitlist',
  stockStatus: 'Sold Out',
};

// ABOUT DATA
export const aboutData = {
  paragraphOne: '',
  stores: [
    {
      id: nanoid(),
      img: 'bestbuy.svg',
      alt: 'bestbuy',
    },
    {
      id: nanoid(),
      img: 'shopify.svg',
      alt: 'shopify',
    },
    {
      id: nanoid(),
      img: 'supreme.svg',
      alt: 'supreme',
    },
    {
      id: nanoid(),
      img: 'target.svg',
      alt: 'target',
    },
    {
      id: nanoid(),
      img: 'walmart.svg',
      alt: 'walmart',
    },
  ],
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'ksr1.jpg',
    title: "Crazy about sports cards? We've got you covered.",
    info: 'KSR has earned a reputation for demolishing EVERY sports card release. In most cases, our users are able to checkout the overwhelming majority of online stock.',
    info2: '',
    url: '',
  },
  {
    id: nanoid(),
    img: 'ksr2.jpg',
    title: 'What about sneakers?',
    info: "Our developers aren't just developers, but also sneakerheads and hypebeasts. For this reason, we support several major sneaker retailers to help our users secure the hottest Jordans and Yeezys.",
    info2: '',
    url: '',
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: '',
  qa: [
    {
      question: 'How much does KSR cost?',
      answer: 'We charge 500$ USD for an annual license to use our software.',
    },
    {
      question: 'How do I purchase?',
      answer:
        'Right now, the best chance to purchase KSR is by joining our waitlist. You can do so by scrolling up and clicking the Join Our Waitlist button.',
    },
    {
      question: 'What sites do you support?',
      answer:
        'We support a variety of sites including sports cards retailers, sneaker retailers, and many others. Visit our twitter page to see our recent success!',
    },
    {
      question: 'What countries do you support?',
      answer: 'Our current focus is US retailers only, but we are also adding Canada support.',
    },
    {
      question: 'What do I need to run the software?',
      answer:
        'You must have a Windows computer and access to proxy servers in order to properly run our software.',
    },
  ],
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/ksrautomation',
    },
    {
      id: nanoid(),
      name: 'instagram',
      url: 'https://twitter.com/ksrautomation',
    },
    {
      id: nanoid(),
      name: 'envelope',
      url: 'mailto:ksroboticsautomation@gmail.com',
    },
  ],
};
